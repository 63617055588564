import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React from 'react';

import { Alert, Text } from '~/components/blocks';
import { AppointmentStatus, ReceptionUberDeliveryFragment, UberDeliveryStatus } from '~/graphql';
import { useUberOrganizationSetting } from '~/hooks/use-uber_organization_setting';

import { UberDeliveryFeeInfo } from './UberDeliveryFeeInfo';

type Props = {
  appointmentStatus: AppointmentStatus;
  uberDelivery?: ReceptionUberDeliveryFragment | null;
};

const CustomAlert = styled(Alert)(({ theme }) =>
  css({
    '& ul, li': {
      listStyleType: 'initial',
      listStylePosition: 'inside',
      marginLeft: '15px',
      textIndent: '-19px',
      fontSize: theme.fontSizes.s,
    },
  }),
);

export const SameDayDeliveryInfo = React.memo((props: Props) => {
  const theme = useTheme();

  const { appointmentStatus, uberDelivery } = props;
  const { enable: enabledUber, loading } = useUberOrganizationSetting();

  if (loading) {
    return null;
  }

  if (
    appointmentStatus === AppointmentStatus.Cancelled ||
    appointmentStatus === AppointmentStatus.Noshow
  ) {
    return null;
  }

  if (!enabledUber && !uberDelivery) {
    return (
      <Alert status="error" marginTop={theme.space.m}>
        設定が無効になっているため、当日配達を利用できません。患者にご相談の上、受け渡し方法を変更してください
      </Alert>
    );
  }

  if (uberDelivery && uberDelivery.status === UberDeliveryStatus.UberSupportCanceled) {
    return (
      <Alert status="warning" marginTop={theme.space.m}>
        <b>
          Uber&nbsp;Eats&nbsp;により配達がキャンセルされました。配達状況を確認の上、Pharmsサポートからご連絡します
        </b>
      </Alert>
    );
  }

  if (
    appointmentStatus === AppointmentStatus.WaitingForCharge &&
    uberDelivery?.isTimeElapsedNotification &&
    uberDelivery.status === UberDeliveryStatus.Unfulfilled
  ) {
    return (
      <Alert status="warning" marginTop={theme.space.m}>
        <b>
          配達員とマッチングできなかったため、集荷依頼がキャンセルされました。再度依頼をしてください。
          <br />
          配達利用料は集荷依頼後に確定します
        </b>
      </Alert>
    );
  }

  // 集荷に時間がかかっており、その後にマッチもしていない場合
  if (
    appointmentStatus === AppointmentStatus.WaitingForCharge &&
    uberDelivery?.isTimeElapsedNotification &&
    uberDelivery.status === UberDeliveryStatus.Pending
  ) {
    return (
      <CustomAlert status="warning" marginTop={theme.space.m}>
        <b>配達員のマッチングに時間がかかっています</b>
        <br />
        <ul>
          <li>
            受け渡し方法を変更するとより早くお薬をお渡しできるかもしれません。患者にご相談ください
          </li>
          <li>患者との合意が取れたら、必ず受け渡し方法を変更してください</li>
        </ul>
      </CustomAlert>
    );
  }

  if (appointmentStatus === AppointmentStatus.WaitingForCharge && !uberDelivery) {
    return (
      <Alert status="info" marginTop={theme.space.m}>
        集荷依頼をしてください。配達利用料は集荷依頼後に確定します
      </Alert>
    );
  }

  if (
    appointmentStatus === AppointmentStatus.WaitingForCharge &&
    uberDelivery?.status === UberDeliveryStatus.Pending
  ) {
    return (
      <>
        <Alert status="info" marginTop={theme.space.m}>
          <Text>集荷依頼中です。配達員とマッチング次第、通知にてお知らせします</Text>
        </Alert>
        <UberDeliveryFeeInfo uberDeliveryFee={uberDelivery.fee} />
      </>
    );
  }

  if (
    (appointmentStatus === AppointmentStatus.WaitingForCharge ||
      appointmentStatus === AppointmentStatus.Charged) &&
    uberDelivery?.status === UberDeliveryStatus.Pickup
  ) {
    return (
      <>
        <Alert status="info" marginTop={theme.space.m}>
          配達員が確定しました。配達員が到着次第、注文番号をご確認の上お薬をお渡しください
        </Alert>
        <UberDeliveryFeeInfo uberDeliveryFee={uberDelivery.fee} />
      </>
    );
  }

  if (uberDelivery?.status === UberDeliveryStatus.Dropoff) {
    return (
      <Alert status="info" marginTop={theme.space.m}>
        お薬を配達中です。配達が完了次第、通知にてお知らせします
      </Alert>
    );
  }

  if (
    uberDelivery?.status === UberDeliveryStatus.Canceled &&
    appointmentStatus === AppointmentStatus.WaitingForCharge
  ) {
    return (
      <Alert status="warning" marginTop={theme.space.m}>
        <b>
          配達がキャンセルされました。配達員からお薬を受け取り、患者とお薬のお渡し方法について相談してください。対応を完了するには会計操作が必要です
        </b>
      </Alert>
    );
  }

  if (
    uberDelivery?.status === UberDeliveryStatus.Canceled &&
    appointmentStatus === AppointmentStatus.Charged
  ) {
    return (
      <Alert status="warning" marginTop={theme.space.m}>
        <b>
          配達がキャンセルされました。配達員からお薬を受け取り、患者とお薬のお渡し方法について相談の上、対応を完了してください
        </b>
      </Alert>
    );
  }

  return null;
});

SameDayDeliveryInfo.displayName = 'SameDayDeliveryInfo';
