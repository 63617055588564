import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useRecoilCallback } from 'recoil';

import { Alert, Box, Flex, Icon, Text } from '~/components/blocks';
import { changeDeliveryMethodState } from '~/state/partials/change_delivery_method_modal/atoms';
import { ChangeDeliveryMethodModal } from '~/state/partials/change_delivery_method_modal/types';

type Props = {
  appointmentId: string;
  availableSameDayDelivery: boolean;
  showPatientAddressConfirmation?: boolean;
};

const Root = styled('div')(({ theme }) =>
  css({
    paddingBottom: theme.space.m,
    paddingTop: '0',
  }),
);

const Content = styled(Box)(({ theme }) =>
  css({
    textAlign: 'center',
    padding: theme.space.s,
    marginRight: theme.space.m,
    marginLeft: theme.space.m,
    borderRadius: theme.radii.default,
    color: 'white',
    backgroundColor: theme.colors.background.secondary,
    lineHeight: '1.3em',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.5,
    },
  }),
);

export const SameDayDeliveryContent = (props: Props) => {
  const theme = useTheme();
  const [isClicked, setIsClicked] = useState(false);
  const { appointmentId, availableSameDayDelivery } = props;

  const onClick = useRecoilCallback(
    ({ set }) =>
      () => {
        set(changeDeliveryMethodState, (_state: ChangeDeliveryMethodModal) => ({
          ..._state,
          isOpen: true,
          appointmentId,
        }));
        setIsClicked(true); // 一回でもクリックされたら開かないようにContentは表示しないようにする
      },
    [appointmentId, setIsClicked],
  );

  if (!availableSameDayDelivery) {
    return (
      <Root>
        <Alert status="warning" mt={theme.space.s} marginX={theme.space.m}>
          <b>
            薬局から患者指定住所まで距離が離れているため、当日配達を利用できません。患者にご相談の上、受け渡し方法を変更してください
          </b>
        </Alert>
      </Root>
    );
  }

  return (
    <Root>
      {!availableSameDayDelivery ? (
        <Alert status="warning" mt={theme.space.s} marginX={theme.space.m}>
          薬局から患者指定住所まで距離が離れているため、当日配達を利用できません。患者にご相談の上、受け渡し方法を変更してください
        </Alert>
      ) : !isClicked && !props.showPatientAddressConfirmation ? (
        <Content onClick={onClick}>
          <Text color="white" size="xs" fontWeight="bold" marginLeft={theme.space.l}>
            Uber&nbsp;Eats&nbsp;による当日配達の希望患者です
          </Text>
          <Flex alignItems="center" justifyContent="center">
            <Icon icon="alert" size="l" color="white" marginRight="0px" />
            <Text color="white" size="m" fontWeight="bold" marginLeft={theme.space.s}>
              必ず患者と配達情報をご確認ください
            </Text>
          </Flex>
        </Content>
      ) : null}
    </Root>
  );
};
