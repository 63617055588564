import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import { FormikErrors, FormikProps } from 'formik';
import { produce } from 'immer';
import React, { useCallback, useMemo } from 'react';

import {
  Alert,
  Box,
  Button,
  EntryList,
  Flex,
  Icon,
  Modal,
  Radio,
  RadioGroup,
  Text,
  Tooltip,
} from '~/components/blocks';
import {
  LineItemField,
  OptionalLineItem,
  OptionalLineItemField,
  SimplePatientProfile,
  TotalAmount,
} from '~/components/partials';
import { UberDeliveryLineItem } from '~/components/partials/UberDeliveryLineItem';
import { Charge } from '~/constants/charge';
import {
  AppointmentDeliveryMethod,
  ChargeAppointmentFragment,
  ChargeCompanyFeeSettingFragment,
  ChargeFeeSettingFragment,
  ChargeOrganizationCompanyFeeSettingFragment,
  LineItemCode,
  UberDeliveryStatus,
} from '~/graphql';
import { SystemAmount } from '~/hooks/use-system_amount';
import { Label } from '~/utils/label';

import { UberDeliveryFreePromotionText } from '../UberDeliveryFreePromotionText';
import { DeliveryMethodField } from './DeliveryMethodField';
import { Fields } from './types';

type Props = {
  appointment: ChargeAppointmentFragment;
  formik: FormikProps<Fields>;
  feeSettings: (
    | ChargeFeeSettingFragment
    | ChargeCompanyFeeSettingFragment
    | ChargeOrganizationCompanyFeeSettingFragment
  )[];
  needsDeliveryMethod: boolean;
  systemAmount: SystemAmount;
  onConfirm: () => void;
};

const ButtonAdd = styled(
  ({
    className,
    disabled,
    onClick,
  }: {
    className?: string;
    disabled: boolean;
    onClick?: () => void;
  }) => (
    <button className={className} disabled={disabled} onClick={onClick}>
      <Icon icon="plus" size="l" />
      項目追加
    </button>
  ),
)(({ theme }) =>
  css({
    display: 'inline-flex',
    alignItems: 'center',
    background: theme.colors.background.default,
    border: `1px dashed ${theme.colors.border.default}`,
    padding: `${theme.space.m} ${theme.space.l}`,
    borderRadius: theme.radii.default,
    color: theme.colors.text.default,
    lineHeight: theme.lineHeights.s,
    outline: 'none',
    transitionDuration: theme.transitions.fast,
    '&:hover': {
      background: theme.colors.background.bg,
      cursor: 'pointer',
    },
    '&:disabled': {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  }),
);

const ButtonAddWrapper = styled('span')(({ theme }) =>
  css({
    display: 'inline-block',
    marginTop: theme.space.m,
  }),
);

const ButtonDelete = styled('button')(({ theme }) =>
  css({
    display: 'flex',
    width: '30px',
    height: '30px',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.colors.background.bg,
    border: theme.borders.transparent,
    borderRadius: theme.radii.circle,
    marginTop: '10px',
    marginLeft: theme.space.l,
    outline: 'none',
    transitionDuration: theme.transitions.fast,
    '&:hover': {
      background: theme.colors.background.default,
      border: theme.borders.default,
      cursor: 'pointer',
    },
  }),
);

const getTotalAmount = (values: Fields) => {
  return [values.lineItem, ...values.optionalLineItems].reduce(
    (result, { amount = 0 }) => result + +amount,
    0,
  );
};

export const InputPanel = React.memo((props: Props) => {
  const { appointment, formik, onConfirm } = props;
  const isUberSupportCanceled =
    appointment.uberDelivery?.status === UberDeliveryStatus.UberSupportCanceled;
  const isValidFee = appointment.uberDelivery?.isValidFee;
  const isSameDayDelivery =
    appointment.deliveryMethod === AppointmentDeliveryMethod.SameDayDelivery;
  const disabledChargeWithUberDeliveryFee = isSameDayDelivery && !isValidFee;
  const disabled = (isUberSupportCanceled || disabledChargeWithUberDeliveryFee) ?? false;
  const isFreePromotion = !!appointment.uberDelivery?.isFreePromotion;
  const theme = useTheme();
  const optionalFeeSettings = props.feeSettings.filter(({ fixed }) => !fixed);
  const existedFeeSetting = optionalFeeSettings.length > 0;
  const isInvalidAmountWithApp = useMemo(() => {
    const withApp = formik.values.paymentMethod === 'app';
    const totalAmount = getTotalAmount(formik.values);
    // Stripeの決済で1〜49円は受付ない
    return (
      withApp &&
      ((0 < totalAmount && totalAmount < Charge.minAmountCredit) ||
        Charge.maxAmountCredit < totalAmount)
    );
  }, [formik.values]);
  const isInvalidAmountWithCash = useMemo(() => {
    const withCash = formik.values.paymentMethod === 'cash';
    const totalAmount = getTotalAmount(formik.values);
    return withCash && Charge.maxAmount < totalAmount;
  }, [formik.values]);
  const handleConfirm = useCallback(async () => {
    const error = await formik.validateForm();
    const isValid = !error || Object.keys(error).length === 0;

    if (!isInvalidAmountWithApp && !isInvalidAmountWithCash && isValid) {
      onConfirm();
    }
  }, [formik, isInvalidAmountWithApp, isInvalidAmountWithCash, onConfirm]);

  return (
    <>
      <Modal.Body>
        <SimplePatientProfile patientId={appointment.patient?.id || ''} />
        {disabled && (
          <Alert status="error">
            当日配達の状況を確認中につき、会計できません。配達状況を確認の上、Pharmsサポートからご連絡します
          </Alert>
        )}
        {isInvalidAmountWithApp && (
          <Alert status="error">
            アプリ決済の場合は、合計金額が0円または{Label.amount(Charge.minAmountCredit)}以上
            {Label.amount(Charge.maxAmountCredit)}
            以下になるように金額を入力してください
          </Alert>
        )}
        {isInvalidAmountWithCash && (
          <Alert status="error">
            窓口決済の場合は、合計金額が{Label.amount(Charge.maxAmount)}
            以下になるように金額を入力してください
          </Alert>
        )}
        <EntryList marginTop={theme.space.l}>
          <EntryList.Head>支払い方法</EntryList.Head>
          <EntryList.Body>
            {appointment.telemedicine ? (
              <Text size="l" fontWeight={theme.fontWeights.bold}>
                アプリ決済
              </Text>
            ) : appointment.appPayment ? (
              <RadioGroup>
                <Radio
                  label="窓口決済"
                  name="paymentMethod"
                  value="cash"
                  disabled={disabled}
                  checked={formik.values.paymentMethod === 'cash'}
                  onChange={formik.handleChange}
                />
                <Radio
                  label="アプリ決済"
                  name="paymentMethod"
                  value="app"
                  disabled={disabled}
                  checked={formik.values.paymentMethod === 'app'}
                  onChange={formik.handleChange}
                />
              </RadioGroup>
            ) : (
              <Box mr={theme.space.xxxl}>
                <Tooltip
                  content={
                    <>
                      利用可能なクレジットカードの登録がないため
                      <br />
                      窓口で決済してください
                    </>
                  }
                  placement={'top-end'}
                >
                  <RadioGroup>
                    <Radio label="窓口決済" name="paymentMethod" value="cash" checked={true} />
                    <Radio label="アプリ決済" name="paymentMethod" value="app" disabled={true} />
                  </RadioGroup>
                </Tooltip>
              </Box>
            )}
          </EntryList.Body>
        </EntryList>
        <Box mt={theme.space.l}>
          <LineItemField
            values={formik.values.lineItem}
            errors={formik.errors.lineItem}
            onChange={(newValues) => {
              formik.setFieldValue('lineItem', newValues);
            }}
          />
          {formik.values.optionalLineItems.map((lineItem, idx) => (
            <Flex key={idx} mt={theme.space.m}>
              {lineItem.code === LineItemCode.UberDeliveryFee ? (
                <UberDeliveryLineItem lineItem={lineItem} />
              ) : (
                <>
                  <OptionalLineItemField
                    feeSettings={optionalFeeSettings}
                    values={lineItem}
                    errors={
                      (
                        formik.errors.optionalLineItems as
                          | FormikErrors<OptionalLineItem>[]
                          | undefined
                      )?.[idx]
                    }
                    onChange={(_values) => {
                      const newLineItems = produce(formik.values.optionalLineItems, (draft) => {
                        draft[idx] = _values;
                      });
                      formik.setFieldValue('optionalLineItems', newLineItems);
                    }}
                  />
                  <ButtonDelete
                    onClick={() => {
                      const newLineItems = formik.values.optionalLineItems.filter(
                        (_, i) => i !== idx,
                      );
                      formik.setFieldValue('optionalLineItems', newLineItems);
                    }}
                  >
                    <Icon icon="close2" size="xl" />
                  </ButtonDelete>
                </>
              )}
            </Flex>
          ))}
          {existedFeeSetting ? (
            <ButtonAddWrapper>
              <ButtonAdd
                disabled={!existedFeeSetting || disabled}
                onClick={() =>
                  formik.setFieldValue('optionalLineItems', [
                    ...formik.values.optionalLineItems,
                    {
                      feeSettingId: '',
                      code: '',
                      subject: '',
                      amount: '',
                    },
                  ])
                }
              />
            </ButtonAddWrapper>
          ) : (
            <Tooltip placement="top" content="項目が登録されていないため追加できません">
              <ButtonAddWrapper>
                <ButtonAdd disabled />
              </ButtonAddWrapper>
            </Tooltip>
          )}
        </Box>
        {isFreePromotion && <UberDeliveryFreePromotionText />}
        <TotalAmount lineItems={[formik.values.lineItem, ...formik.values.optionalLineItems]} />
        {props.needsDeliveryMethod && (
          <DeliveryMethodField
            value={formik.values.deliveryMethod}
            onChange={(deliveryMethod) =>
              formik.setFieldValue('deliveryMethod', deliveryMethod || null)
            }
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button use="base" onClick={handleConfirm} disabled={disabled}>
          確認
        </Button>
      </Modal.Footer>
    </>
  );
});

InputPanel.displayName = 'InputPanel';
