import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Property } from 'node_modules/csstype';
import React, { ComponentProps } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Box, Chip, Flex, Icon, Text } from '~/components/blocks';
import { AppointmentStatus, ReceptionUberDeliveryFragment, UberDeliveryStatus } from '~/graphql';

type Props = {
  size?: ComponentProps<typeof Chip>['size'];
  uberDelivery?: ReceptionUberDeliveryFragment | null | undefined;
  status: AppointmentStatus;
  isSameDayDelivery?: boolean;
};

const DetailLabel = styled(Box)<{ marginLeft: Property.MarginLeft }>(({ marginLeft }) =>
  css({
    marginLeft: marginLeft,
    position: 'relative',
    top: '3px',
  }),
);

export const UberDeliveryAppointmentStatusLabel = (props: Props) => {
  const theme = useTheme();
  const { status, uberDelivery, isSameDayDelivery, ...componentProps } = props;
  const isTablet = useMediaQuery({ query: theme.mediaQueries.tablet });

  const uberDeliveryStatus = uberDelivery?.status;

  if (!isSameDayDelivery) {
    return null;
  }

  if (!uberDelivery && status === AppointmentStatus.WaitingForCharge) {
    return (
      <Flex flexDirection="column" alignContent="center">
        <Box>
          <Chip size="fixed" {...componentProps} color="cyan" textColor="white">
            集荷待ち
          </Chip>
        </Box>
        <DetailLabel marginLeft={`calc(${theme.space.m} + ${theme.space.s})`}>
          <Icon icon="uber" size={isTablet ? 's' : 'm'} color="pink" />
          <Text color="pink" fontWeight="bold" size={isTablet ? 'xs' : 's'}>
            未依頼
          </Text>
        </DetailLabel>
      </Flex>
    );
  }

  if (uberDeliveryStatus === UberDeliveryStatus.UberSupportCanceled) {
    return (
      <Flex flexDirection="column" alignContent="center">
        <Box>
          <Chip size="fixed" {...componentProps} color="grey" textColor="white">
            配達待ち
          </Chip>
        </Box>
        <DetailLabel marginLeft={theme.space.xs}>
          <Icon icon="uber" size={isTablet ? 's' : 'm'} color="pink" />
          <Text color="pink" fontWeight="bold" size={isTablet ? 'xs' : 's'}>
            キャンセル
          </Text>
        </DetailLabel>
      </Flex>
    );
  }

  if (
    uberDeliveryStatus === UberDeliveryStatus.Unfulfilled &&
    status === AppointmentStatus.WaitingForCharge
  ) {
    return (
      <Flex flexDirection="column" alignContent="center">
        <Box>
          <Chip size="fixed" {...componentProps} color="cyan" textColor="white">
            集荷待ち
          </Chip>
        </Box>
        <DetailLabel marginLeft={`calc(${theme.space.m} + ${theme.space.s})`}>
          <Icon icon="uber" size={isTablet ? 's' : 'm'} color="pink" />
          <Text color="pink" fontWeight="bold" size={isTablet ? 'xs' : 's'}>
            未依頼
          </Text>
        </DetailLabel>
      </Flex>
    );
  }

  if (uberDeliveryStatus === UberDeliveryStatus.Dropoff && status === AppointmentStatus.Charged) {
    return (
      <Box>
        <Chip size="fixed" {...componentProps} color="grey" textColor="white">
          配達待ち
        </Chip>
      </Box>
    );
  }

  if (
    uberDeliveryStatus === UberDeliveryStatus.Canceled &&
    (status === AppointmentStatus.WaitingForCharge || status === AppointmentStatus.Charged)
  ) {
    return (
      <Flex flexDirection="column" alignContent="center">
        <Box>
          <Chip size="fixed" {...componentProps} color="grey" textColor="white">
            配達待ち
          </Chip>
        </Box>
        <DetailLabel marginLeft={theme.space.xs}>
          <Icon icon="uber" size={isTablet ? 's' : 'm'} color="pink" />
          <Text color="pink" fontWeight="bold" size={isTablet ? 'xs' : 's'}>
            キャンセル
          </Text>
        </DetailLabel>
      </Flex>
    );
  }

  if (
    uberDeliveryStatus === UberDeliveryStatus.Pickup &&
    (status === AppointmentStatus.WaitingForCharge || status === AppointmentStatus.Charged)
  ) {
    return (
      <Flex flexDirection="column" alignContent="center">
        <Box>
          <Chip size="fixed" {...componentProps} color="cyan" textColor="white">
            集荷待ち
          </Chip>
        </Box>
        <DetailLabel marginLeft={theme.space.xs}>
          <Icon icon="uber" size={isTablet ? 's' : 'm'} color="green" />
          <Text color="green" fontWeight="bold" size={isTablet ? 'xs' : 's'}>
            配達員待ち
          </Text>
        </DetailLabel>
      </Flex>
    );
  }

  if (
    uberDeliveryStatus === UberDeliveryStatus.Pending &&
    status === AppointmentStatus.WaitingForCharge
  ) {
    return (
      <Box>
        <Chip size="fixed" {...componentProps} color="cyan" textColor="white">
          集荷待ち
        </Chip>
      </Box>
    );
  }

  return null;
};
