import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import { useRecoilCallback } from 'recoil';

import { Alert, Box, EntryList, Flex, Grid, Icon, Loader, Tag, Text } from '~/components/blocks';
import {
  AppointmentStatusLabel,
  DispensingRequestCollapse,
  InsurnceCardCollapse,
  MemoField,
  ReceptionTypeIcon,
} from '~/components/partials';
import { ReceiveOptionBlock } from '~/components/partials/ReceiveOptionBlock';
import { AppointmentDeliveryMethod, KarteAppointmentFragment } from '~/graphql';
import { useUberOrganizationSetting } from '~/hooks/use-uber_organization_setting';
import { filePageInfoState } from '~/state/partials/telemedicine_patient_profile/atoms';
import { translateReason } from '~/utils/appointments';
import { Label } from '~/utils/label';
import { openPreviewPartnerPage } from '~/utils/pf_dispensing_request';

import { TabType } from '..';
import { MedicineNoteButton } from './MedicineNoteButton';
import { MedixsReceptionId } from './MedixsReceptionId';
import { SameDayDeliveryContent } from './SameDeliveryContent';
import { useUpdateMemo } from './use-update-memo';

type Props = {
  partner: boolean;
  appointment?: KarteAppointmentFragment | null;
  onChangeTab: (tab: TabType) => void;
  isPartnerActionsPage: boolean;
};

const CustomTag = styled(Tag)(({ theme }) =>
  css({
    background: theme.colors.colorPallete.grey04,
    border: 'none',
  }),
);

export const ReceptionPane = React.memo((props: Props) => {
  const { appointment } = props;
  const theme = useTheme();

  const { enable: enabledUber, loading } = useUberOrganizationSetting();
  const { isUpdating, latestMemo, update } = useUpdateMemo(appointment?.id);

  const handleChangeMemo = useCallback(
    async (memo: string) => {
      try {
        await update(memo);
      } catch {
        // 何もしない
      }
    },
    [update],
  );

  const handleClickCertificate = useRecoilCallback(
    ({ set }) =>
      () => {
        if (appointment?.patient?.id) {
          const patientId = appointment.patient.id;
          props.onChangeTab('file');
          set(filePageInfoState, (_state) => ({
            ..._state,
            patientId,
            page: 1,
            filter: { isSent: false, isReceived: true },
          }));
        }
      },
    [appointment?.patient?.id, props],
  );

  return (
    <Box position="relative" height="100%" overflow="auto" padding={theme.space.l}>
      {!appointment || loading ? (
        <Loader open inside appearance="white" />
      ) : (
        <>
          <MedixsReceptionId medixsReception={appointment.medixsReception} />
          <Grid gridTemplateColumns="min-content auto" paddingTop={theme.space.m}>
            <Flex justifyContent="center" alignItems="center" mt={theme.space.s}>
              <ReceptionTypeIcon telemedicine={true} size="xxxl" mr="xxs" />
            </Flex>
            <Flex
              alignItems="center"
              marginY={theme.space.m}
              justifyContent="space-between"
              ml={theme.space.s}
            >
              <Text fontWeight="bold" size="m">
                オンライン服薬指導
              </Text>

              <Box marginLeft="auto">
                {appointment && (
                  <AppointmentStatusLabel
                    status={appointment.status}
                    uberDelivery={appointment.uberDelivery}
                    isSameDayDelivery={
                      appointment.deliveryMethod === AppointmentDeliveryMethod.SameDayDelivery
                    }
                  />
                )}
              </Box>
            </Flex>

            {appointment.start && appointment.end && (
              <>
                <Flex justifyContent="center" alignItems="center">
                  <Icon icon="time" size="xl" />
                </Flex>
                <Flex ml={theme.space.s}>
                  <Text fontWeight="bold" size="m">
                    {Label.YYYYMMDDja(appointment.start)}
                  </Text>
                  <Text fontWeight="bold" size="m" marginLeft={theme.space.s}>
                    {Label.HHMM(appointment.start)}-{Label.HHMM(appointment.end)}
                  </Text>
                </Flex>
              </>
            )}
          </Grid>
          <ReceiveOptionBlock appointment={appointment} isOnline={true}>
            {appointment.deliveryMethod == AppointmentDeliveryMethod.SameDayDelivery &&
              enabledUber && (
                <SameDayDeliveryContent
                  appointmentId={appointment.id}
                  availableSameDayDelivery={appointment.quote.availableSameDayDelivery}
                  showPatientAddressConfirmation={props.isPartnerActionsPage}
                />
              )}
          </ReceiveOptionBlock>
          {appointment.deliveryMethod == AppointmentDeliveryMethod.SameDayDelivery &&
            !enabledUber && (
              <Alert status="error" mt={theme.space.m}>
                <b>
                  設定が無効になっているため、当日配達を利用できません。患者にご相談の上、受け渡し方法を変更してください
                </b>
              </Alert>
            )}
          <DispensingRequestCollapse
            printable={false}
            appointmentId={appointment.id}
            onPreview={
              props.partner ? (appointmentId) => openPreviewPartnerPage(appointmentId) : undefined
            }
          />
          <InsurnceCardCollapse
            patientId={appointment.patient?.id}
            isActivePatient={!!appointment.patient?.active}
            onClickCertificate={handleClickCertificate}
          />
          <EntryList mt={theme.space.l}>
            <MedicineNoteButton
              patient={appointment.patient}
              permission={appointment.hiccupAppointmentPermission}
              status={appointment.status}
            />
          </EntryList>
          {appointment.start && appointment.end && (
            <EntryList mt={theme.space.l}>
              <EntryList.Head>申し込み日時</EntryList.Head>
              <EntryList.Body>
                <Flex>
                  <Box>
                    <Text size="m">
                      {appointment?.createdAt ? Label.YYYYMMDDja(appointment.createdAt) : null}
                    </Text>
                    <Text size="m">
                      {appointment?.createdAt ? Label.HHMM(appointment.createdAt) : null}
                    </Text>
                  </Box>
                  <Box>
                    <Text size="m" ml={theme.space.s}>
                      （オンライン服薬指導）
                    </Text>
                  </Box>
                </Flex>
              </EntryList.Body>
            </EntryList>
          )}
          <EntryList mt={theme.space.l}>
            <EntryList.Head>メモ</EntryList.Head>
            <EntryList.Body>
              <CustomTag>
                <Flex alignItems="center">
                  <Icon icon="smartphone" size="l" />
                  <Text size="m">CLINICS会員</Text>
                </Flex>
              </CustomTag>
              <MemoField
                disabled={isUpdating}
                value={latestMemo.mutationCalled ? latestMemo.memo : appointment.description || ''}
                onChange={handleChangeMemo}
              />
            </EntryList.Body>
          </EntryList>
          {appointment.cancellationReason && (
            <EntryList mt={theme.space.l}>
              <EntryList.Head>キャンセル内容</EntryList.Head>
              <EntryList.Body>
                <Text fontWeight="bold">
                  {translateReason(
                    appointment.cancellationReason,
                    appointment.status,
                    appointment.telemedicine ? undefined : !!appointment.waitingForChargeAt,
                  )}
                </Text>
              </EntryList.Body>
            </EntryList>
          )}
        </>
      )}
    </Box>
  );
});

ReceptionPane.displayName = 'ReceptionPane';
