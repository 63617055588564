import dynamic from 'next/dynamic';
import React from 'react';

import { Box, Grid } from '~/components/blocks';
import { usePractitionerRole } from '~/hooks/use-practitioner-role';

import { PatientProfilePanel } from './PatientProfilePanel';
import { PermissionError } from './PermissionError';
import { useFetchEncounter } from './use-fetch-encounter';

const TelemedicinePanel = dynamic(() => import('./TelemedicinePanel'), {
  ssr: false,
});

type Props = {
  encounterId: string;
  firebaseUidPrefix?: string;
};

export const Telemedicine = React.memo((props: Props) => {
  const { isInitializing, encounter } = useFetchEncounter(props.encounterId);

  const { verifyRole } = usePractitionerRole();
  const isVerified = verifyRole('pharmacist');

  const encounterRawId = encounter?.rawId;

  return (
    <Grid gridTemplateColumns="1fr min-content" gridTemplateRows="1fr" overflow="hidden">
      <Box position="relative" overflow="auto">
        {!isInitializing ? (
          isVerified && encounterRawId ? (
            <>
              <TelemedicinePanel
                sessionId={props.encounterId}
                encounterRawId={encounterRawId}
                firebaseUidPrefix={props.firebaseUidPrefix}
              />
            </>
          ) : (
            <PermissionError />
          )
        ) : null}
      </Box>
      <Box>
        <PatientProfilePanel
          appointment={encounter?.appointment}
          patient={encounter?.appointment.patient}
        />
      </Box>
    </Grid>
  );
});

Telemedicine.displayName = 'Telemedicine';
