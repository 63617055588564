import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { PropsWithChildren } from 'react';

import { Alert, Box, Text } from '~/components/blocks';
import { ReceptionPaneDraftAppointmentFragment } from '~/graphql';

type Props = {
  draftAppointment: ReceptionPaneDraftAppointmentFragment;
};

const HelpLink = styled('a')(({ theme }) =>
  css({
    color: theme.colors.text.link,
    ':hover': {
      textDecoration: 'none',
    },
  }),
);

const Info = ({ children }: PropsWithChildren) => {
  const theme = useTheme();

  return (
    <Box padding={theme.space.m}>
      <Alert status="info">{children}</Alert>
    </Box>
  );
};

export const StaffActionInfo = ({ draftAppointment }: Props) => {
  const { status, pfDispensingRequest } = draftAppointment;

  if (status === 'available') {
    return (
      <Info>
        医療機関から処方箋をFAX等で受理したら、患者に服薬指導の日時指定を依頼してください。
        <br />
        受付の流れ・対応方法は
        <HelpLink
          target="_blank"
          rel="noopener noreferrer"
          href="https://intercom.help/pharms/ja/articles/8885090"
        >
          こちら
        </HelpLink>
      </Info>
    );
  }

  if (status === 'wait_for_booking' && pfDispensingRequest?.sendMethod === 'FAX') {
    return (
      <Info>
        <Text>患者に日時指定依頼を送信しました。患者の希望日時確定をお待ちください</Text>
      </Info>
    );
  }

  if (status === 'wait_for_booking' && pfDispensingRequest?.sendMethod === 'UPLOAD') {
    return (
      <Info>
        <Text>処方箋がアップロードされました。患者の希望日時確定をお待ちください</Text>
      </Info>
    );
  }

  return null;
};
